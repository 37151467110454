import Vue from "vue";
import VueRouter from "vue-router";


Vue.use(VueRouter);

const routes = [{
        path: "/login",
        name: "loginApp",
        component: () =>
            import ("@/views/Login"),
        beforeEnter(to, from, next) {
            const user = localStorage.getItem("user-token");
            const admin = localStorage.getItem("admin-token");

            // student
            if (user || admin) {
                next("/");
            } else {
                next();
            }
        },
    },
    {
        path: "/",
        name: "Dashboard",
        component: () =>
            import ("@/layouts/Dashboard"),
        beforeEnter(to, from, next) {
            const user = localStorage.getItem("user-token");
            const admin = localStorage.getItem("admin-token");

            // student
            if (user || admin) {
                next();
            } else {
                next("/login");
            }
        },
        children: [{
                path: "/",
                name: "Dash",
                // component: Home,
                component: () =>
                    import ("../views/About"),
            },
            {
                path: "/school-year",
                name: "SchoolYears",
                component: () =>
                    import ("../views/SchoolYear/index"),
            },
            {
                path: "/user",
                name: "Users",
                component: () =>
                    import ("../views/User/index"),
            },
            {
                path: "/document-validation",
                name: "DocumentValidation",
                component: () =>
                    import ("../views/Document/Validation"),
            },
            {
                path: "/setting",
                name: "Setting",
                component: () =>
                    import ("../views/Setting/index"),
            },
            {
                path: "/other",
                name: "Other",
                component: () =>
                    import ("../views/Other/index"),
            },
            {
                path: "/school-year/:id",
                name: "SchoolYear",
                component: () =>
                    import ("../views/SchoolYear/_id"),
            },
            {
                path: "/faculty",
                name: "Faculties",
                component: () =>
                    import ("../views/Faculty/index"),
            },
            {
                path: "/bilan",
                name: "Bilan",
                component: () =>
                    import ("../views/Bilan/index"),
            },
            {
                path: "/faculty/:id",
                name: "Faculty",
                component: () =>
                    import ("../views/Faculty/_id"),
            },
            {
                path: "/faculty/department/:id",
                name: "Department",
                component: () =>
                    import ("../views/Faculty/Department/_id"),
            },

            {
                path: "/ue",
                name: "Ues",
                component: () =>
                    import ("../views/Ue/index"),
            },
            {
                path: "/ue/:id",
                name: "Ue",
                component: () =>
                    import ("../views/Ue/_id"),
            },
            {
                path: "/ue/ecue/:id",
                name: "Ecue",
                component: () =>
                    import ("../views/Ue/Ecue/_id"),
            },
            {
                path: "/ue/ecue/:id/student",
                name: "EcueStudent",
                component: () =>
                    import ("../views/Ue/Ecue/Student/index"),
            },
            {
                path: "/ue/ecue/:ecue/student/:id/mark",
                name: "EcueStudent",
                component: () =>
                    import ("../views/Ue/Ecue/Student/Mark/index"),
            },
            {
                path: "/grade",
                name: "Grades",
                component: () =>
                    import ("../views/Grade/index"),
            },
            {
                path: "/request",
                name: "Requests",
                component: () =>
                    import ("../views/Admin/Request/index"),
            },
            {
                path: "/hourlyquota",
                name: "HourlyQuotas",
                component: () =>
                    import ("../views/HourlyQuota/index"),
            },
            {
                path: "/teacher",
                name: "Teachers",
                component: () =>
                    import ("../views/Teacher/index"),
            },
            {
                path: "/hall",
                name: "Halls",
                component: () =>
                    import ("../views/Hall/index"),
            },
            {
                path: "/godfather",
                name: "Godfathers",
                component: () =>
                    import ("../views/Godfather/index"),
            },
            {
                path: "/schorlarship",
                name: "Schorlarships",
                component: () =>
                    import ("../views/Schorlarship/index"),
            },
            {
                path: "/student/register",
                name: "StudentRegister",
                component: () =>
                    import ("../views/Student/Register/index"),
            },
            {
                path: "/additional-standard-fees",
                name: "Caisse",
                component: () =>
                    import ("../views/StandardFees/index"),
            },
            {
                path: "/caisse",
                name: "Caisse",
                component: () =>
                    import ("../views/Caisse/index"),
            },
            {
                path: "/caisse/student",
                name: "CaisseStudents",
                component: () =>
                    import ("../views/Caisse/Student/index"),
            },

            {
                path: "/caisse/student/:id",
                name: "CaisseStudent",
                component: () =>
                    import ("../views/Caisse/Student/_id"),
            },
            {
                path: "/caisse/stitch",
                name: "CaisseStudents",
                component: () =>
                    import ("../views/Caisse/Stitch/index"),
            },
            {
                path: "/finance",
                name: "Finance",
                component: () =>
                    import ("../views/Finance/index"),
            },
            {
                path: "/finance/student",
                name: "FinanceStudents",
                component: () =>
                    import ("../views/Finance/Student/index"),
            },

            {
                path: "/finance/student/:id",
                name: "FinanceStudent",
                component: () =>
                    import ("../views/Finance/Student/_id"),
            },
            {
                path: "/finance/stitch",
                name: "FinanceStudents",
                component: () =>
                    import ("../views/Finance/Stitch/index"),
            },

            {
                path: "/student",
                name: "Students",
                component: () =>
                    import ("../views/Student/index"),
            },
            {
                path: "/student/:id",
                name: "Student",
                component: () =>
                    import ("../views/Student/_id/index"),
            },



        ],
    },

    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/About.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;