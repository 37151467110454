import axios from "axios";
// axios.defaults.baseURL = "http://localhost:3068/api/v1";
// axios.defaults.baseURL = "https://ganvekon.com/backend/api/v1";
axios.defaults.baseURL = "https://sm.irgibafrica.university/backend/api/v1";
// axios.defaults.baseURL = "http://192.168.43.239:3000/api/v1";
// axios.defaults.baseURL = "http://192.168.1.104:3068/api/v1";
// axios.defaults.baseURL = "http://192.168.100.23:3068/api/v1";

let token = "";
if (localStorage.getItem("admin-token")) {
    token = localStorage.getItem("admin-token");
    axios.defaults.headers.common["admin-token"] = token;
} else if (localStorage.getItem("user-token")) {
    token = localStorage.getItem("user-token");
    axios.defaults.headers.common["user-token"] = token;
}



axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        localStorage.clear()
        location.reload()
    }
    return error;
});


axios.defaults.headers.common["Content-Type"] = "application/json";

// axios.defaults.headers.common["auth-token"] = token;