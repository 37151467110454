import axios from "axios";
const state = {
    UserProfile: {},
    priority: {},
    callList: {},
    rdvList: {},
    Users: [],
    SchoolYears: [],
    SchoolYear: {},
    Semesters: [],
    Faculties: [],
    Faculty: {},
    Departments: [],
    Department: {},
    Options: [],
    Option: {},
    AdditionalCoasts: [],
    StandardFees: [],
    Ues: [],
    Ue: {},
    Ecues: [],
    Ecue: {},
    Grades: [],
    Grade: {},
    HourlyQuotas: [],
    HourlyQuota: {},
    Students: [],
    Student: {},
    Teachers: [],
    Teacher: {},
    Halls: [],
    Hall: {},
    Godfathers: [],
    Godfather: {},
    Scholarships: [],
    Schorlarship: {},
    Products: [],
    Product: {},
    Schools: [],
    School: {},
    DailyStitch: {},
    Request: {},
    Requests: [],
    Operation: {},
    Operations: [],
    ControlMarks: [],
    ExamMarks: [],
    EcueMarks: [],
    EcueMark: {},




    File: {},
    Calls: {},
    Quoting: {},
    QuotingRequests: {},
    QuotingRequest: {},
    Contract: {},
    Contracts: [],
    ContractsList: [],
    FileEndorsements: [],
    CompanyReturns: {},
    CustomerReturn: {},
    Declarations: [],
    Declaration: {},
    Victims: [],
    Victime: [],
    Documents: [],
    GlobalMails: {},
    searchContract: [],
    searchGlobalMail: [],
    statCall: [],
    statPV: [],
    statRecap: [],
    statRecapAsk: [],
    statExpert: [],
    statOffre: [],
    statbon: [],
    statRDV: [],
    statClient: [],
    statQuoting: [],
    statCourrier: [],
    statEncaissement: [],
    statCoass: [],
    statRegul: [],
    statPB: [],
    StatDeclarations: [],
    statReass: [],
    statProspect: [],
    statContrat: [],
    statPaiement: [],
    statImpayer: [],
    statEcheance: [],
    inventory: [],
    statFileCall: "",
    statFileRdv: "",
    statFileClient: "",
    statFileProspect: "",
    statFileCourrier: "",
    //production
    statFileCotation: "",
    statFileEmission: "",
    statFileEncaissement: "",
    statFilePaiement: "",
    statFileImpaye: "",
    statFileEcheance: "",
    statFileCoas: "",
    statFileReass: "",
    statFileRegule: "",
    statFilePb: "",
    //sinistre
    statFileDeclaration: "",
    statFilePv: "",
    statFileBon: "",
    statFileExpertise: "",
    statFileReglement: "",
    statFileInventaire: "",
    //Recap
    statFileRecap: "",
    statFileASK: "",
    Facture: [],
};

const getters = {
    UserProfile: (state) => state.UserProfile,
    priority: (state) => state.priority,
    callList: (state) => state.callList,
    rdvList: (state) => state.rdvList,
    Users: (state) => state.Users,
    SchoolYears: (state) => state.SchoolYears,
    SchoolYear: (state) => state.SchoolYear,
    Semesters: (state) => state.Semesters,
    Faculties: (state) => state.Faculties,
    Faculty: (state) => state.Faculty,
    Departments: (state) => state.Departments,
    Department: (state) => state.Department,
    Options: (state) => state.Options,
    Option: (state) => state.Option,
    AdditionalCoasts: (state) => state.AdditionalCoasts,
    StandardFees: (state) => state.StandardFees,
    Ues: (state) => state.Ues,
    Ue: (state) => state.Ue,
    Ecues: (state) => state.Ecues,
    Ecue: (state) => state.Ecue,
    Grades: (state) => state.Grades,
    Grade: (state) => state.Grade,
    HourlyQuotas: (state) => state.HourlyQuotas,
    HourlyQuota: (state) => state.HourlyQuota,
    Students: (state) => state.Students,
    Student: (state) => state.Student,
    Teachers: (state) => state.Teachers,
    Teacher: (state) => state.Teacher,
    Halls: (state) => state.Halls,
    Hall: (state) => state.Hall,
    Godfathers: (state) => state.Godfathers,
    Godfather: (state) => state.Godfather,
    Scholarships: (state) => state.Scholarships,
    Schorlarship: (state) => state.Schorlarship,
    Products: (state) => state.Products,
    Product: (state) => state.Product,
    Schools: (state) => state.Schools,
    School: (state) => state.School,
    DailyStitch: (state) => state.DailyStitch,
    Request: (state) => state.Request,
    Requests: (state) => state.Requests,
    Operation: (state) => state.Operation,
    Operations: (state) => state.Operations,
    ControlMarks: (state) => state.ControlMarks,
    ExamMarks: (state) => state.ExamMarks,
    EcueMark: (state) => state.EcueMark,
    EcueMarks: (state) => state.EcueMarks,




    File: (state) => state.File,
    Calls: (state) => state.Calls,
    Quoting: (state) => state.Quoting,
    QuotingRequests: (state) => state.QuotingRequests,
    QuotingRequest: (state) => state.QuotingRequest,
    Contract: (state) => state.Contract,
    Contracts: (state) => state.Contracts,
    ContractsList: (state) => state.ContractsList,
    FileEndorsements: (state) => state.FileEndorsements,
    CompanyReturns: (state) => state.CompanyReturns,
    CustomerReturn: (state) => state.CustomerReturn,
    Declarations: (state) => state.Declarations,
    Declaration: (state) => state.Declaration,
    Victims: (state) => state.Victims,
    Victime: (state) => state.Victime,
    Documents: (state) => state.Documents,
    GlobalMails: (state) => state.GlobalMails,
    searchContract: (state) => state.searchContract,
    searchGlobalMail: (state) => state.searchGlobalMail,
    statCall: (state) => state.statCall,
    statPV: (state) => state.statPV,
    statRecap: (state) => state.statRecap,
    statRecapAsk: (state) => state.statRecapAsk,
    statExpert: (state) => state.statExpert,
    statOffre: (state) => state.statOffre,
    statbon: (state) => state.statbon,
    statRDV: (state) => state.statRDV,
    statClient: (state) => state.statClient,
    statQuoting: (state) => state.statQuoting,
    statCourrier: (state) => state.statCourrier,
    statEncaissement: (state) => state.statEncaissement,
    statCoass: (state) => state.statCoass,
    statRegul: (state) => state.statRegul,
    statPB: (state) => state.statPB,
    statReass: (state) => state.statReass,
    StatDeclarations: (state) => state.StatDeclarations,
    statProspect: (state) => state.statProspect,
    statContrat: (state) => state.statContrat,
    statPaiement: (state) => state.statPaiement,
    statImpayer: (state) => state.statImpayer,
    statEcheance: (state) => state.statEcheance,
    inventory: (state) => state.inventory,
    statFileCall: (state) => state.statFileCall,
    statFileRdv: (state) => state.statFileRdv,
    statFileClient: (state) => state.statFileClient,
    statFileProspect: (state) => state.statFileProspect,
    statFileCourrier: (state) => state.statFileCourrier,
    //production
    statFileCotation: (state) => state.statFileCotation,
    statFileEmission: (state) => state.statFileEmission,
    statFileEncaissement: (state) => state.statFileEncaissement,
    statFilePaiement: (state) => state.statFilePaiement,
    statFileImpaye: (state) => state.statFileImpaye,
    statFileEcheance: (state) => state.statFileEcheance,
    statFileCoas: (state) => state.statFileCoas,
    statFileReass: (state) => state.statFileReass,
    statFileRegule: (state) => state.statFileRegule,
    statFilePb: (state) => state.statFilePb,
    //sinistre
    statFileDeclaration: (state) => state.statFileDeclaration,
    statFilePv: (state) => state.statFilePv,
    statFileBon: (state) => state.statFileBon,
    statFileExpertise: (state) => state.statFileExpertise,
    statFileReglement: (state) => state.statFileReglement,
    statFileInventaire: (state) => state.statFileInventaire,
    //Recap
    statFileRecap: (state) => state.statFileRecap,
    statFileASK: (state) => state.statFileASK,
    Facture: (state) => state.Facture,
};

const mutations = {
    SET_USER_PROFILE(state, data) {
        state.UserProfile = data;
    },
    SET_SCHOOL_YEARS(state, data) {
        state.SchoolYears = data;
    },

    SET_SEMESTERS(state, data) {
        state.Semesters = data.data;
        state.SchoolYear = data.schoolYear;
    },
    SET_DEPARTMENTS(state, data) {
        state.Departments = data.data;
        state.Faculty = data.faculty;
    },
    SET_OPTIONS(state, data) {
        state.Options = data;
        // state.Faculty = data.faculty;
    },

    SET_DEPARTMENT(state, data) {
        state.Department = data;
    },
    SET_FACULTIES(state, data) {
        state.Faculties = data;
    },
    SET_ADDTIONAL_COASTS(state, data) {
        state.AdditionalCoasts = data;
    },
    SET_STANDARD_FEES(state, data) {
        state.StandardFees = data;
    },
    SET_UES(state, data) {
        state.Ues = data;
    },
    SET_ECUES(state, data) {
        state.Ecues = data.data;
        state.Ue = data.ue;
    },
    SET_ECUE(state, data) {
        state.Ecue = data;
    },
    SET_GRADES(state, data) {
        state.Grades = data;
    },
    SET_HOURLY_QUOTAS(state, data) {
        state.HourlyQuotas = data;
    },
    SET_STUDENTS(state, data) {
        state.Students = data;
    },
    SET_STUDENT(state, data) {
        state.Student = data;
    },
    SET_CONTROL_MARKS(state, data) {
        state.ControlMarks = data;
    },
    SET_EXAM_MARKS(state, data) {
        state.ExamMarks = data;
    },
    SET_ECUE_MARKS(state, data) {
        state.EcueMarks = data;
    },
    SET_ECUE_MARK(state, data) {
        state.EcueMark = data;
    },
    SET_TEACHERS(state, data) {
        state.Teachers = data;
    },
    SET_HALLS(state, data) {
        state.Halls = data;
    },
    SET_GODFATHERS(state, data) {
        state.Godfathers = data;
    },
    SET_SCHOLARSHIPS(state, data) {
        state.Scholarships = data;
    },
    SET_PRODUCTS(state, data) {
        state.Products = data;
    },
    SET_SCHOOLS(state, data) {
        state.Schools = data;
    },
    SET_SCHOOL(state, data) {
        state.School = data;
    },
    SET_DAILY_STITCH(state, data) {
        state.DailyStitch = data;
    },
    SET_REQUEST(state, data) {
        state.Request = data;
    },
    SET_REQUESTS(state, data) {
        state.Requests = data;
    },
    SET_OPERATION(state, data) {
        state.Operation = data;
    },
    SET_OPERATIONS(state, data) {
        state.Operations = data;
    },
    SET_USERS(state, data) {
        state.Users = data;
    },





    SET_USER_PRIORITY(state, data) {
        state.priority = data;
    },
    SET_CALL_LIST(state, data) {
        state.callList = data;
    },
    SET_RDV_LIST(state, data) {
        state.rdvList = data;
    },


    SET_FILE(state, data) {
        state.File = data;
    },
    SET_CALLS(state, data) {
        state.Calls = data;
    },
    SET_QUOTING(state, data) {
        state.Quoting = data;
    },
    SET_QUOTING_REQUESTS(state, data) {
        state.QuotingRequests = data;
    },
    SET_QUOTING_REQUEST(state, data) {
        state.QuotingRequest = data;
    },
    SET_CONTRACT(state, data) {
        state.Contract = data;
    },
    SET_CONTRACTS(state, data) {
        state.Contracts = data;
    },
    SET_CONTRACTS_LIST(state, data) {
        state.ContractsList = data;
    },
    SET_FILE_ENDORSEMENT(state, data) {
        state.FileEndorsements = data;
    },
    SET_COMPANY_RETURNS(state, data) {
        state.CompanyReturns = data;
    },
    SET_CUSTOMER_RETURN(state, data) {
        state.CustomerReturn = data;
    },
    SET_DECLARATIONS(state, data) {
        state.Declarations = data;
    },
    SET_STATDECLARATIONS(state, data) {
        state.StatDeclarations = data;
    },
    SET_DECLARATION(state, data) {
        state.Declaration = data;
    },
    SET_VICTIMS(state, data) {
        state.Victims = data;
    },
    SET_VICTIM(state, data) {
        state.Victime = data;
    },
    SET_DOCUMENTS(state, data) {
        state.Documents = data;
    },
    SET_GLOBALS_MAILS(state, data) {
        state.GlobalMails = data;
    },
    SET_SEARCH_CONTRACT(state, data) {
        state.searchContract = data;
    },
    SET_SEARCH_GLOBAL_MAIL(state, data) {
        state.GlobalMails = data;
    },
    SET_STAT_CALL(state, data) {
        state.statCall = data;
    },
    SET_STAT_PV(state, data) {
        state.statPV = data;
    },
    SET_STAT_RECAP(state, data) {
        state.statRecap = data;
    },
    SET_STAT_RECAPASK(state, data) {
        state.statRecapAsk = data;
    },
    SET_STAT_EXPERT(state, data) {
        state.statExpert = data;
    },
    SET_STAT_OFFRE(state, data) {
        state.statOffre = data;
    },
    SET_STAT_BON(state, data) {
        state.statbon = data;
    },
    SET_STAT_RDV(state, data) {
        state.statRDV = data;
    },
    SET_STAT_Client(state, data) {
        state.statClient = data;
    },
    SET_STAT_Quoting(state, data) {
        state.statQuoting = data;
    },
    SET_STAT_Courrier(state, data) {
        state.statCourrier = data;
    },
    SET_STAT_Encaissemnet(state, data) {
        state.statEncaissement = data;
    },
    SET_STAT_COASS(state, data) {
        state.statCoass = data;
    },
    SET_STAT_REGUL(state, data) {
        state.statRegul = data;
    },
    SET_STAT_PB(state, data) {
        state.statPB = data;
    },
    SET_STAT_REASS(state, data) {
        state.statReass = data;
    },
    SET_STAT_PROSPECT(state, data) {
        state.statProspect = data;
    },
    SET_STAT_CONTRAT(state, data) {
        state.statContrat = data;
    },
    SET_STAT_PAIEMENT(state, data) {
        state.statPaiement = data;
    },
    SET_STAT_IMPAYER(state, data) {
        state.statImpayer = data;
    },
    SET_STAT_ECHEANCE(state, data) {
        state.statEcheance = data;
    },
    SET_INVENTORY(state, data) {
        state.inventory = data;
    },
    SET_STATFILECALL(state, data) {
        state.statFileCall = data;
    },
    SET_STATFILERDV(state, data) {
        state.statFileRdv = data;
    },
    SET_STATFILECLIENT(state, data) {
        state.statFileClient = data;
    },
    SET_STATFILEPROSPECT(state, data) {
        state.statFileProspect = data;
    },
    SET_STATFILECOURRIER(state, data) {
        state.statFileCourrier = data;
    },
    //production
    SET_STATFILECOTATION(state, data) {
        state.statFileCotation = data;
    },
    SET_STATFILEEMISSION(state, data) {
        state.statFileEmission = data;
    },
    SET_STATFILEENCAISSEMENT(state, data) {
        state.statFileEncaissement = data;
    },
    SET_STATFILEPAIEMENT(state, data) {
        state.statFilePaiement = data;
    },
    SET_STATFILEIMPAYE(state, data) {
        state.statFileImpaye = data;
    },
    SET_STATFILEECHEANCE(state, data) {
        state.statFileEcheance = data;
    },
    SET_STATFILECOASS(state, data) {
        state.statFileCoas = data;
    },
    SET_STATFILEREASS(state, data) {
        state.statFileReass = data;
    },
    SET_STATFILEREGULE(state, data) {
        state.statFileRegule = data;
    },
    SET_STATFILEPB(state, data) {
        state.statFilePb = data;
    },
    //Sinistre
    SET_STATFILEDECLARATION(state, data) {
        state.statFileDeclaration = data;
    },
    SET_STATFILEPV(state, data) {
        state.statFilePv = data;
    },
    SET_STATFILEBON(state, data) {
        state.statFileBon = data;
    },
    SET_STATFILEEXPERTISE(state, data) {
        state.statFileExpertise = data;
    },
    SET_STATFILEREGLEMENT(state, data) {
        state.statFileReglement = data;
    },
    SET_STATFILEINVENTAIRE(state, data) {
        state.statFileInventaire = data;
    },
    //Recap
    SET_STATFILERECAP(state, data) {
        state.statFileRecap = data;
    },
    SET_STATFILEASK(state, data) {
        state.statFileASK = data;
    },
    SET_FACTURE(state, data) {
        state.Facture = data;
    },
};

const actions = {
    // statistique
    getSchoolYears({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/schoolyear`
                )
                .then((res) => {
                    commit("SET_SCHOOL_YEARS", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_SCHOOL_YEARS", []);
                    reject(err);
                    console.log(err);
                });
        });
    },

    getSemesters({ commit }, { schoolYear }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/semester?schoolYear=${schoolYear}`
                )
                .then((res) => {
                    commit("SET_SEMESTERS", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_SEMESTERS", { data: [], schoolYear: {} });
                    reject(err);
                    console.log(err);
                });
        });
    },

    getDepartments({ commit }, { faculty }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/department?faculty=${faculty}`
                )
                .then((res) => {
                    commit("SET_DEPARTMENTS", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_DEPARTMENTS", { data: [], faculty: {} });
                    reject(err);
                    console.log(err);
                });
        });
    },

    getDepartment({ commit }, { department }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/department/${department}`
                )
                .then((res) => {
                    commit("SET_DEPARTMENT", res.data.data);

                    axios.get(
                            `/standardfees?department=${department}`
                        )
                        .then((res) => {
                            commit("SET_STANDARD_FEES", res.data.data);

                            axios.get(
                                    `/additionalcoast?department=${department}`
                                )
                                .then((res) => {
                                    commit("SET_ADDTIONAL_COASTS", res.data.data);
                                    resolve(true);

                                })
                                .catch((err) => {
                                    commit("SET_ADDTIONAL_COASTS", []);
                                    reject(err);
                                    console.log(err);
                                });

                            resolve(true);

                        })
                        .catch((err) => {
                            commit("SET_STANDARD_FEES", []);
                            reject(err);
                            console.log(err);
                        });


                    resolve(true);

                })
                .catch((err) => {
                    commit("SET_DEPARTMENT", {});
                    reject(err);
                    console.log(err);
                });
        });
    },
    getOptions({ commit }, { department }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/option?department=${department}`
                )
                .then((res) => {
                    commit("SET_OPTIONS", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_OPTIONS", { data: [], department: {} });
                    reject(err);
                    console.log(err);
                });
        });
    },
    getAdditionalAndStandardFees({ commit }, { department = "", withoutDepartment = true }) {
        return new Promise((resolve, reject) => {
            axios.get(
                    `/standardfees?department=${department}&withoutDepartment=${withoutDepartment}`
                )
                .then((res) => {
                    commit("SET_STANDARD_FEES", res.data.data);

                    axios.get(
                            `/additionalcoast?department=${department}&withoutDepartment=${withoutDepartment}`
                        )
                        .then((res) => {
                            commit("SET_ADDTIONAL_COASTS", res.data.data);
                            resolve(true);

                        })
                        .catch((err) => {
                            commit("SET_ADDTIONAL_COASTS", []);
                            reject(err);
                            console.log(err);
                        });

                    resolve(true);

                })
                .catch((err) => {
                    commit("SET_STANDARD_FEES", []);
                    reject(err);
                    console.log(err);
                });

        });
    },


    getFaculties({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/faculty`
                )
                .then((res) => {
                    commit("SET_FACULTIES", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_FACULTIES", []);
                    reject(err);
                    console.log(err);
                });
        });
    },



    getUes({ commit }, { schoolYear }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/ue?schoolYear=${schoolYear}`
                )
                .then((res) => {
                    commit("SET_UES", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_UES", []);
                    reject(err);
                    console.log(err);
                });
        });
    },


    getEcues({ commit }, { ue }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/ecue?ue=${ue}`
                )
                .then((res) => {
                    commit("SET_ECUES", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_ECUES", { data: [], ue: {} });
                    reject(err);
                    console.log(err);
                });
        });
    },

    getEcue({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/ecue/${id}`
                )
                .then((res) => {
                    commit("SET_ECUE", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_ECUE", {});
                    reject(err);
                    console.log(err);
                });
        });
    },

    getGrades({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/grade`
                )
                .then((res) => {
                    commit("SET_GRADES", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_GRADES", []);
                    reject(err);
                    console.log(err);
                });
        });
    },

    getHourlyQuotas({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/hourlyquota`
                )
                .then((res) => {
                    commit("SET_HOURLY_QUOTAS", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_HOURLY_QUOTAS", []);
                    reject(err);
                    console.log(err);
                });
        });
    },

    getStudents({ commit }, filter = { department: '', currentLevel: '', schoolFeesPercentage: '', badgeSort: '', ecue: '' }) {
        let { department, currentLevel, schoolFeesPercentage, badgeSort, ecue } = filter

        if (!ecue) ecue = ""
        if (!currentLevel) currentLevel = ""
        if (!badgeSort) badgeSort = ""
        if (!department) department = ""

        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/student?department=${department}&currentLevel=${currentLevel}&schoolFeesPercentage=${schoolFeesPercentage}&badgeSort=${badgeSort}&ecue=${ecue}`
                )
                .then((res) => {
                    commit("SET_STUDENTS", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_STUDENTS", []);
                    reject(err);
                    console.log(err);
                });
        });
    },
    getStudent({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/student/${id}`
                )
                .then((res) => {
                    commit("SET_STUDENT", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_STUDENT", {});
                    reject(err);
                    console.log(err);
                });
        });
    },
    getControlMarks({ commit }, { student, level, ecue, schoolYear }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/mark?student=${student}&type=control&level=${level}&ecue=${ecue}&schoolYear=${schoolYear}`
                )
                .then((res) => {
                    commit("SET_CONTROL_MARKS", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_CONTROL_MARKS", []);
                    reject(err);
                    console.log(err);
                });
        });
    },
    getExamMarks({ commit }, { student, level, ecue, schoolYear }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/mark?student=${student}&type=exam&level=${level}&ecue=${ecue}&schoolYear=${schoolYear}`
                )
                .then((res) => {
                    commit("SET_EXAM_MARKS", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_EXAM_MARKS", []);
                    reject(err);
                    console.log(err);
                });
        });
    },

    getDailyStitch({ commit }, {
        periodStartDate,
        periodEndDate,
        standardFeesName,
        standardCoastName,
        option,
        inCash,
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/user/daily-stitch?periodEndDate=${periodEndDate}&periodStartDate=${periodStartDate}&standardFeesName=${standardFeesName}&standardCoastName=${standardCoastName}&optionName=${option}&inCash=${inCash}`
                )
                .then((res) => {
                    commit("SET_DAILY_STITCH", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_DAILY_STITCH", {});
                    reject(err);
                    console.log(err);
                });
        });
    },
    getRequest({ commit }, {
        type = '',
        status = '',
        addressToMe
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/request?lastRequest=true&type=${type}&status=${status}&addressToMe=${addressToMe}`
                )
                .then((res) => {
                    commit("SET_REQUEST", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_REQUEST", {});
                    reject(err);
                    console.log(err);
                });
        });
    },
    getRequests({ commit }, {
        type = '',
        status = '',
        addressToMe
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/request?type=${type}&status=${status}&addressToMe=${addressToMe}`
                )
                .then((res) => {
                    commit("SET_REQUESTS", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_REQUESTS", {});
                    reject(err);
                    console.log(err);
                });
        });
    },
    getOperation({ commit }, {
        type = '',
        status = '',
        addressToMe
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/operation?lastRequest=true&type=${type}&status=${status}&addressToMe=${addressToMe}`
                )
                .then((res) => {
                    commit("SET_OPERATION", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_OPERATION", {});
                    reject(err);
                    console.log(err);
                });
        });
    },
    getOperations({ commit }, {
        type = '',
        student = '',
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/operation?type=${type}&student=${student}`
                )
                .then((res) => {
                    commit("SET_OPERATIONS", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_OPERATIONS", {});
                    reject(err);
                    console.log(err);
                });
        });
    },

    getTeachers({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/teacher`
                )
                .then((res) => {
                    commit("SET_TEACHERS", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_TEACHERS", []);
                    reject(err);
                    console.log(err);
                });
        });
    },
    getHalls({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/hall`
                )
                .then((res) => {
                    commit("SET_HALLS", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_HALLS", []);
                    reject(err);
                    console.log(err);
                });
        });
    },
    getGodfathers({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/godfather`
                )
                .then((res) => {
                    commit("SET_GODFATHERS", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_GODFATHERS", []);
                    reject(err);
                    console.log(err);
                });
        });
    },

    getScholarships({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/scholarship`
                )
                .then((res) => {
                    commit("SET_SCHOLARSHIPS", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_SCHOLARSHIPS", []);
                    reject(err);
                    console.log(err);
                });
        });
    },

    getProducts({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/product`
                )
                .then((res) => {
                    commit("SET_PRODUCTS", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_PRODUCTS", []);
                    reject(err);
                    console.log(err);
                });
        });
    },
    getSchools({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/school`
                )
                .then((res) => {
                    commit("SET_SCHOOLS", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_SCHOOLS", []);
                    reject(err);
                    console.log(err);
                });
        });
    },
    getMySchool({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/school/id`
                )
                .then((res) => {
                    commit("SET_SCHOOL", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_SCHOOL", {});
                    reject(err);
                    console.log(err);
                });
        });
    },




    // fin statistique

    getFacture({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/invoice`)
                .then((res) => {
                    commit("SET_FACTURE", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    getStateProspect({ commit }, {
        fileName = "",
        periodStartDate = "",
        periodEndDate = "",
        currentYear = "",
        withoutPeriod = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/statistical/file?prospect=true&fileName=${fileName}&periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}&currentYear=${currentYear}&withoutPeriod=${withoutPeriod}`
                )
                .then((res) => {
                    commit("SET_STAT_PROSPECT", res.data.data);
                    commit("SET_STATFILEPROSPECT", res.data.link);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    getQuotingSearchRequests({ commit }, {
        //setUpContract = true,
        // contractEstablished = "",
        product = "",
        fileName = "",
        periodStartDate = "",
        periodEndDate = "",
        status = "",
        withoutPeriod = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/quoting/request/search?status=${status}&product=${product}&fileName=${fileName}&periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}&withoutPeriod=${withoutPeriod}`
                )
                .then((res) => {
                    commit("SET_QUOTING_REQUESTS", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_QUOTING_REQUESTS", {});
                    reject(err);

                    console.log(err);
                });
        });
    },
    getVictimsSearch({ commit }, { thirdName = "", thirdPartyContact = "" }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/sinister/declaration/victim/search?thirdName=${thirdName}&thirdPartyContact=${thirdPartyContact}`
                )
                .then((res) => {
                    commit("SET_VICTIMS", res.data.data);
                    resolve(res.data);
                })
                .catch((err) => {
                    commit("Victims", []);
                    reject(err);
                    console.log(err);
                });
        });
    },
    // getInventorySearch(
    //   { commit },
    //   {
    //     fileName = "",
    //     company = "",
    //     product = "",
    //     sinisterAskNumber = "",
    //     startDate = "",
    //     endDate = "",
    //   }
    // ) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get(
    //         `/sinister/declaration/search?fileName=${fileName}&startDate=${startDate}&endDate=${endDate}&company=${company}&product=${product}&sinisterAskNumber=${sinisterAskNumber}`
    //       )
    //       .then((res) => {
    //         commit("SET_DECLARATIONS", res.data.data);

    //         resolve(res.data.data);
    //       })
    //       .catch((err) => {
    //         commit("SET_DECLARATIONS", []);
    //         reject(err);
    //         console.log(err);
    //       });
    //   });
    // },
    getDeclarationSearch({ commit }, {
        fileName = "",
        company = "",
        product = "",
        sinisterAskNumber = "",
        sinisterCompanyNumber = "",
        startDate = "",
        endDate = "",
        currentYear = "",
        withoutPeriod = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/sinister/declaration/search?fileName=${fileName}&withoutPeriod=${withoutPeriod}&currentYear=${currentYear}&startDate=${startDate}&endDate=${endDate}&company=${company}&product=${product}&sinisterAskNumber=${sinisterAskNumber}&sinisterCompanyNumber=${sinisterCompanyNumber}`
                )
                .then((res) => {
                    commit("SET_DECLARATIONS", res.data.data);

                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_DECLARATIONS", []);
                    reject(err);
                    console.log(err);
                });
        });
    },
    getStateContrat({ commit }, {
        fileName = "",
        periodStartDate = "",
        periodEndDate = "",
        company = "",
        product = "",
        currentYear = "",
        withoutPeriod = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/statistical/contract?fileName=${fileName}&withoutPeriod=${withoutPeriod}&currentYear=${currentYear}&periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}&company=${company}&product=${product}`
                )
                .then((res) => {
                    commit("SET_STAT_CONTRAT", res.data.data);
                    commit("SET_STATFILEEMISSION", res.data.link);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    getStateecheance({ commit }, {
        withDeadlineNotice = true,
        deadlineNoticeAlreadyClosed = "*",
        status = "",
        fileName = "",
        periodStartDate = "",
        periodEndDate = "",
        company = "",
        product = "",
        currentYear = "",
        withoutPeriod = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/statistical/contract?fileName=${fileName}&withoutPeriod=${withoutPeriod}&currentYear=${currentYear}&periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}&company=${company}&product=${product}&withDeadlineNotice=${withDeadlineNotice}&deadLineNoticeStatus =${status}&deadlineNoticeAlreadyClosed =${deadlineNoticeAlreadyClosed}`
                )
                .then((res) => {
                    commit("SET_STAT_ECHEANCE", res.data.data);
                    commit("SET_STATFILEECHEANCE", res.data.link);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    getStatePaiement({ commit }, {
        fileName = "",
        collectionStartDate = "",
        collectionEndDate = "",
        periodStartDate = "",
        periodEndDate = "",
        netPrime = "",
        company = "",
        product = "",
        currentYear = "",
        withoutPeriod = "",
    }) {
        return new Promise((resolve, reject) => {
            //alert("OK");
            axios
                .get(
                    `/statistical/contract?payed=true&netPrime=${netPrime}&withoutPeriod=${withoutPeriod}&currentYear=${currentYear}&fileName=${fileName}&collectionStartDate=${collectionStartDate}&collectionEndDate=${collectionEndDate}&periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}&company=${company}&product=${product}`
                )
                .then((res) => {
                    commit("SET_STAT_PAIEMENT", res.data.data);
                    commit("SET_STATFILEPAIEMENT", res.data.link);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    getStateImpaye({ commit }, {
        fileName = "",
        company = "",
        product = "",
        periodStartDate = "",
        periodEndDate = "",
        currentYear = "",
        withoutPeriod = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/statistical/contract?notPayed=true&fileName=${fileName}&withoutPeriod=${withoutPeriod}&currentYear=${currentYear}&periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}&company=${company}&product=${product}`
                )
                .then((res) => {
                    commit("SET_STAT_IMPAYER", res.data.data);
                    commit("SET_STATFILEIMPAYE", res.data.link);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    getStateEncaissement({ commit }, {
        fileName = "",
        periodStartDate = "",
        periodEndDate = "",
        company = "",
        product = "",
        currentYear = "",
        withoutPeriod = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/statistical/contract?collection=true&fileName=${fileName}&withoutPeriod=${withoutPeriod}&currentYear=${currentYear}&periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}&company=${company}&product=${product}`
                )
                .then((res) => {
                    commit("SET_STAT_Encaissemnet", res.data.data);
                    commit("SET_STATFILEENCAISSEMENT", res.data.link);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    getStateCoass({ commit }, {
        fileName = "",
        periodStartDate = "",
        periodEndDate = "",
        company = "",
        product = "",
        currentYear = "",
        withoutPeriod = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/statistical/coinsurance?fileName=${fileName}&withoutPeriod=${withoutPeriod}&currentYear=${currentYear}&periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}&company=${company}&product=${product}`
                )
                .then((res) => {
                    commit("SET_STAT_COASS", res.data.data);
                    commit("SET_STATFILECOASS", res.data.link);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    getStateRegul({ commit }, {
        fileName = "",
        periodStartDate = "",
        periodEndDate = "",
        company = "",
        product = "",
        currentYear = "",
        withoutPeriod = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/statistical/regulation?fileName=${fileName}&withoutPeriod=${withoutPeriod}&currentYear=${currentYear}&periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}&company=${company}&product=${product}`
                )
                .then((res) => {
                    commit("SET_STAT_REGUL", res.data.data);
                    commit("SET_STATFILEREGULE", res.data.link);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    getStatePB({ commit }, {
        fileName = "",
        periodStartDate = "",
        periodEndDate = "",
        company = "",
        product = "",
        currentYear = "",
        withoutPeriod = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/statistical/pb-clause?fileName=${fileName}&withoutPeriod=${withoutPeriod}&currentYear=${currentYear}&periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}&company=${company}&product=${product}`
                )
                .then((res) => {
                    commit("SET_STAT_PB", res.data.data);
                    commit("SET_STATFILEPB", res.data.link);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    getStateReass({ commit }, {
        fileName = "",
        periodStartDate = "",
        periodEndDate = "",
        company = "",
        product = "",
        currentYear = "",
        withoutPeriod = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/statistical/reinsurance?fileName=${fileName}&withoutPeriod=${withoutPeriod}&currentYear=${currentYear}&periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}&company=${company}&product=${product}`
                )
                .then((res) => {
                    commit("SET_STAT_REASS", res.data.data);
                    commit("SET_STATFILEREASS", res.data.link);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    getStateCourrier({ commit }, {
        mailNumber = "",
        receptionStartDate = "",
        receptionEndDate = "",
        transmitter = "",
        periodStartDate = "",
        periodEndDate = "",
        currentYear = "",
        withoutPeriod = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/statistical/global-mail?mailNumber=${mailNumber}&periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}&withoutPeriod=${withoutPeriod}&currentYear=${currentYear}&receptionStartDate=${receptionStartDate}&receptionEndDate=${receptionEndDate}&transmitter=${transmitter}`
                )
                .then((res) => {
                    commit("SET_STAT_Courrier", res.data.data);
                    commit("SET_STATFILECOURRIER", res.data.link);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    getStateQuoting({ commit }, {
        fileName = "",
        product = "",
        company = "",
        periodStartDate = "",
        periodEndDate = "",
        currentYear = "",
        withoutPeriod = "",
        returnStartDate = "",
        returnEndDate = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/statistical/quoting?fileName=${fileName}&withoutPeriod=${withoutPeriod}&currentYear=${currentYear}&periodEndDate=${periodEndDate}&periodStartDate=${periodStartDate}&product=${product}&company=${company}&returnStartDate=${returnStartDate}&returnEndDate=${returnEndDate}`
                )
                .then((res) => {
                    commit("SET_STAT_Quoting", res.data.data);
                    commit("SET_STATFILECOTATION", res.data.link);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_STAT_Quoting", {});
                    console.log(err);
                    reject(err);
                });
        });
    },
    getStateBon({ commit }, {
        fileName = "",
        startDate = "",
        endDate = "",
        company = "",
        currentYear = "",
        withoutPeriod = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/statistical/coupon?fileName=${fileName}&periodStartDate=${startDate}&periodEndDate=${endDate}&withoutPeriod=${withoutPeriod}&currentYear=${currentYear}&company=${company}`
                )
                .then((res) => {
                    commit("SET_STATFILEBON", res.data.link);
                    commit("SET_STAT_BON", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    getStateOffre({ commit }, {
        fileName = "",
        periodStartDate = "",
        periodEndDate = "",
        product = "",
        company = "",
        status = "",
        emptyOfferSendDate = false,
        emptyOfferReceptDate = false,
        emptyAggreementReceptDate = false,
        emptyCheckReceiptDate = false,
        emptyReceptDateReturn = false,
        currentYear = "",
        withoutPeriod = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/statistical/offer?fileName=${fileName}&periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}&withoutPeriod=${withoutPeriod}&currentYear=${currentYear}&product=${product}&company=${company}&status=${status}&emptyOfferSendDate=${emptyOfferSendDate}&emptyOfferReceptDate=${emptyOfferReceptDate}&emptyAggreementReceptDate=${emptyAggreementReceptDate}&emptyCheckReceiptDate=${emptyCheckReceiptDate}&emptyReceptDateReturn=${emptyReceptDateReturn}`
                )
                .then((res) => {
                    commit("SET_STATFILEREGLEMENT", res.data.link);
                    commit("SET_STAT_OFFRE", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    getStateExpert({ commit }, {
        fileName = "",
        startDate = "",
        endDate = "",
        product = "",
        company = "",
        currentYear = "",
        withoutPeriod = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/statistical/expertise?fileName=${fileName}&periodStartDate=${startDate}&periodEndDate=${endDate}&withoutPeriod=${withoutPeriod}&currentYear=${currentYear}&product=${product}&company=${company}`
                )
                .then((res) => {
                    commit("SET_STATFILEEXPERTISE", res.data.link);
                    commit("SET_STAT_EXPERT", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    getStatePV({ commit }, {
        fileName = "",
        startDate = "",
        endDate = "",
        product = "",
        company = "",
        currentYear = "",
        withoutPeriod = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/statistical/pv?fileName=${fileName}&periodStartDate=${startDate}&periodEndDate=${endDate}&withoutPeriod=${withoutPeriod}&currentYear=${currentYear}&product=${product}&company=${company}`
                )
                .then((res) => {
                    commit("SET_STAT_PV", res.data.data);
                    commit("SET_STATFILEPV", res.data.link);

                    resolve(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    getStateRecap({ commit }, {
        withoutPeriod = "",
        periodStartDate = "",
        periodEndDate = "",
        currentYear = "",
        company = "",
        products = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/statistical/recap?withoutPeriod=${withoutPeriod}&products=${products}&company=${company}&periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}&currentYear=${currentYear}`
                )
                .then((res) => {
                    commit("SET_STAT_RECAP", res.data.data);
                    commit("SET_STATFILERECAP", res.data.link);

                    resolve(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    getStateRecapAsk({ commit }, {
        withoutPeriod = "",
        periodStartDate = "",
        periodEndDate = "",
        currentYear = "",
        products = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/statistical/recap/system?withoutPeriod=${withoutPeriod}&products=${products}&periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}&currentYear=${currentYear}`
                )
                .then((res) => {
                    commit("SET_STAT_RECAPASK", res.data.data);
                    commit("SET_STATFILEASK", res.data.link);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    getStateCall({ commit }, {
        fileName = "",
        periodStartDate = "",
        periodEndDate = "",
        startDate = "",
        endDate = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/statistical/call?fileName=${fileName}&periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}&startDate=${startDate}&endDate=${endDate}`
                )
                .then((res) => {
                    commit("SET_STAT_CALL", res.data.data);
                    commit("SET_STATFILECALL", res.data.link);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    getStateRDV({ commit }, {
        fileName = "",
        periodStartDate = "",
        periodEndDate = "",
        startDate = "",
        endDate = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/statistical/appointment?fileName=${fileName}&periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}&startDate=${startDate}&endDate=${endDate}`
                )
                .then((res) => {
                    commit("SET_STAT_RDV", res.data.data);
                    commit("SET_STATFILERDV", res.data.link);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    getStateClient({ commit }, {
        fileName = "",
        // periodStartDate = "",
        periodEndDate = "",
        currentYear = "",
        withoutPeriod = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/statistical/file?customer=true&fileName=${fileName}&prospectEndDate=${periodEndDate}&currentYear=${currentYear}&withoutPeriod=${withoutPeriod}`
                )
                .then((res) => {
                    commit("SET_STAT_Client", res.data.data);
                    commit("SET_STATFILECLIENT", res.data.link);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    getUserProfile({ commit }) {
        axios
            .get("/user/profile")
            .then((res) => {
                commit("SET_USER_PROFILE", res.data.user);
            })
            .catch((err) => {
                console.log(err);
            });
    },
    getPriority({ commit }) {
        axios
            .get("/user/priority")
            .then((res) => {
                commit("SET_USER_PRIORITY", res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    },
    getCallList({ commit }) {
        axios
            .get("/file/my-call")
            .then((res) => {
                commit("SET_CALL_LIST", res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    },
    getRdvList({ commit }) {
        axios
            .get("/file/my-appointment")
            .then((res) => {
                commit("SET_RDV_LIST", res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    },
    getUsers({ commit }) {
        axios
            .get("/user")
            .then((res) => {
                console.log(res.data.data)
                commit("SET_USERS", res.data.data.docs);
            })
            .catch((err) => {
                console.log(err);
            });
    },

    getFile({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get("/file/" + id)
                .then((res) => {
                    commit("SET_FILE", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_FILE", {});
                    reject(err);
                    console.log(err);
                });
        });
    },
    getCalls({ commit }, id) {
        axios
            .get(`/file/${id}/call`)
            .then((res) => {
                commit("SET_CALLS", res.data.data);
            })
            .catch((err) => {
                commit("SET_CALLS", {});

                console.log(err);
            });
    },

    getQuotingSearch({ commit }, {
        product = "",
        fileName = "",
        quotingRequest = undefined,
        setUpContract = true,
        contractEstablished = false,
        startDate = "",
        endDate = "",
        withoutPeriod = true,
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/quoting/search?product=${product}&withoutPeriod=${withoutPeriod}&fileName=${fileName}&quotingRequest=${quotingRequest}&setUpContract=${setUpContract}&contractEstablished=${contractEstablished}&startDate=${startDate}&endDate=${endDate}`
                )
                .then((res) => {
                    commit("SET_QUOTING", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    // alert(2);
                    commit("SET_QUOTING", {});
                    reject(err);

                    console.log(err);
                });
        });
    },

    getQuoting({ commit }, {
        product,
        file,
        quotingRequest = undefined,
        setUpContract = false,
        contractEstablished = false,
        withoutPeriod = false,
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/quoting?product=${product}&file=${file}&quotingRequest=${quotingRequest}&withoutPeriod=${withoutPeriod}&setUpContract=${setUpContract}&contractEstablished=${contractEstablished}`
                )
                .then((res) => {
                    commit("SET_QUOTING", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    // alert(2);
                    commit("SET_QUOTING", {});
                    reject(err);

                    console.log(err);
                });
        });
    },
    getCustomReturn({ commit }, { id }) {
        axios
            .get(`/quoting/${id}/customer-return`)
            .then((res) => {
                commit("SET_CUSTOMER_RETURN", res.data.data);
            })
            .catch((err) => {
                // alert(2);
                commit("SET_CUSTOMER_RETURN", {});

                console.log(err);
            });
    },

    getQuotingRequests({ commit }, { withoutPeriod = "" }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/quoting/request?withoutPeriod=${withoutPeriod}`)
                .then((res) => {
                    commit("SET_QUOTING_REQUESTS", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_QUOTING_REQUESTS", {});
                    reject(err);

                    console.log(err);
                });
        });
    },
    getQuotingRequest({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/quoting/request/${id}`)
                .then((res) => {
                    commit("SET_QUOTING_REQUEST", res.data.data);

                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_QUOTING_REQUEST", {});
                    reject(err);
                    console.log(err);
                });
        });
    },
    getContract({ commit }, { id, product, quoting = undefined }) {
        axios
            .get(
                "/file/" + id + "/contract?product=" + product + "&quoting=" + quoting
            )
            .then((res) => {
                commit("SET_CONTRACT", res.data.data);
            })
            .catch((err) => {
                commit("SET_CONTRACT", {});

                console.log(err);
            });
    },
    getContractById({ commit }, { id }) {
        axios
            .get("/contract/" + id)
            .then((res) => {
                commit("SET_CONTRACT", res.data.data);
            })
            .catch((err) => {
                commit("SET_CONTRACT", {});

                console.log(err);
            });
    },
    getInventorySearch({ commit }, {
        sinisterCompanyNumber = "",
        sinisterAskNumber = "",
        declarationStartDate = "",
        declarationEndDate = "",
        occurrenceStartDate = "",
        occurrenceEndDate = "",
        fileName = "",
        victimName = "",
        company = "",
        product = "",
        offerSendStartDate = "",
        offerSendEndDate = "",
        checkReceiptStartDate = "",
        checkReceiptEndDate = "",
        currentYear = "",
        withoutPeriod = "",
        status = "*",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/sinister/inventory?checkReceiptEndDate=${checkReceiptEndDate}&currentYear=${currentYear}&withoutPeriod=${withoutPeriod}&checkReceiptStartDate=${checkReceiptStartDate}&offerSendEndDate=${offerSendEndDate}&offerSendStartDate=${offerSendStartDate}&product=${product}&company=${company}&victimName=${victimName}&fileName=${fileName}&sinisterCompanyNumber=${sinisterCompanyNumber}&sinisterAskNumber=${sinisterAskNumber}&declarationStartDate=${declarationStartDate}&declarationEndDate=${declarationEndDate}&occurrenceStartDate=${occurrenceStartDate}&occurrenceEndDate=${occurrenceEndDate}&status=${status}`
                )
                .then((res) => {
                    commit("SET_STATFILEINVENTAIRE", res.data.link);
                    commit("SET_INVENTORY", res.data.data.docs);
                    resolve(res.data.data);
                    console.log(res.data.data);
                })
                .catch((err) => {
                    commit("SET_INVENTORY", []);
                    reject(err);
                    console.log(err);
                });
        });
    },
    getContracts({ commit }, {
        withDeadlineNotice = false,
        notPayed = false,
        payed = false,
        deadLineNoticeStatus = "",
        deadlineNoticeAlreadyClosed = "*",
        contractEstablished = false,
        fromContract = undefined,
        withQuoting = false,
        withoutPeriod = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    "/contract?withDeadlineNotice=" +
                    withDeadlineNotice +
                    "&deadLineNoticeStatus=" +
                    deadLineNoticeStatus +
                    "&deadlineNoticeAlreadyClosed=" +
                    deadlineNoticeAlreadyClosed +
                    "&notPayed=" +
                    notPayed +
                    "&withoutPeriod=" +
                    withoutPeriod +
                    "&payed=" +
                    payed +
                    "&contractEstablished=" +
                    contractEstablished +
                    "&fromContract=" +
                    fromContract +
                    "&withQuoting=" +
                    withQuoting
                )
                .then((res) => {
                    if (res.data.data.docs) {
                        commit("SET_CONTRACTS_LIST", res.data.data.docs);
                        resolve(res.data.data.docs);
                    } else {
                        commit("SET_CONTRACTS", res.data.data);
                        //alert("2")
                        resolve(res.data.data);
                    }
                })
                .catch((err) => {
                    commit("SET_CONTRACTS", []);
                    reject(err);

                    console.log(err);
                });
        });
    },

    getFileEndorsement({ commit }, { id }) {
        axios
            .get("/file/" + id + "/endorsement")
            .then((res) => {
                commit("SET_FILE_ENDORSEMENT", res.data.data);
            })
            .catch((err) => {
                commit("SET_FILE_ENDORSEMENT", []);

                console.log(err);
            });
    },

    getCompanyReturns({ commit }, id) {
        axios
            .get(`/quoting/${id}/company-return`)
            .then((res) => {
                commit("SET_COMPANY_RETURNS", res.data.data);
            })
            .catch((err) => {
                commit("SET_COMPANY_RETURNS", {});

                console.log(err);
            });
    },
    getInventory({ commit }, { status = "Suspens", withoutPeriod = "" }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/sinister/inventory?status=${status}&withoutPeriod=${withoutPeriod}`
                )
                .then((res) => {
                    commit("SET_INVENTORY", res.data.data.docs);
                    commit("SET_STATFILEINVENTAIRE", res.data.link);
                    resolve(res.data.data);
                    console.log(res.data.data);
                })
                .catch((err) => {
                    commit("SET_INVENTORY", []);
                    reject(err);
                    console.log(err);
                });
        });
    },
    getDeclarations({ commit }, { withoutPeriod = "" }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`sinister/declaration?withoutPeriod=${withoutPeriod}`)
                .then((res) => {
                    commit("SET_DECLARATIONS", res.data.data.docs);

                    resolve(res.data.data.docs);
                })
                .catch((err) => {
                    commit("SET_DECLARATIONS", []);
                    reject(err);
                    console.log(err);
                });
        });
    },
    getStatDeclarations({ commit }, {
        fileName = "",
        company = "",
        product = "",
        sinisterAskNumber = "",
        sinisterCompanyNumber = "",
        periodStartDate = "",
        periodEndDate = "",
        startDate = "",
        endDate = "",
        currentYear = "",
        withoutPeriod = "",
    }) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `statistical/declaration?fileName=${fileName}&periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}&withoutPeriod=${withoutPeriod}&currentYear=${currentYear}&startDate=${startDate}&endDate=${endDate}&company=${company}&product=${product}&sinisterAskNumber=${sinisterAskNumber}&sinisterCompanyNumber=${sinisterCompanyNumber}`
                )
                .then((res) => {
                    commit("SET_STATDECLARATIONS", res.data.data);
                    commit("SET_STATFILEDECLARATION", res.data.link);

                    resolve(res.data.data);
                })
                .catch((err) => {
                    // commit("SET_STATDECLARATIONS", []);
                    reject(err);
                    console.log(err);
                });
        });
    },
    getDeclaration({ commit }, { id }) {
        axios
            .get(`sinister/declaration/${id}`)
            .then((res) => {
                commit("SET_DECLARATION", res.data.data);
            })
            .catch((err) => {
                commit("SET_DECLARATION", {});

                console.log(err);
            });
    },
    getVictims({ commit }, { id }) {
        axios
            .get(`sinister/declaration/${id}/victim`)
            .then((res) => {
                commit("SET_VICTIMS", res.data.data.docs);
            })
            .catch((err) => {
                commit("Victims", []);

                console.log(err);
            });
    },
    getVictim({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`sinister/victim/${id}`)
                .then((res) => {
                    commit("SET_VICTIM", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("Victime", []);
                    reject(err);
                    console.log(err);
                });
        });
    },
    getDocuments({ commit }, { name = "" }) {
        axios
            .get(`document?name=${name}`)
            .then((res) => {
                commit("SET_DOCUMENTS", res.data.data);
            })
            .catch((err) => {
                commit("SET_DOCUMENTS", []);

                console.log(err);
            });
    },

    getGlobalMails({ commit }, { withoutPeriod = "" }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/global-mail?withoutPeriod=${withoutPeriod}`)
                .then((res) => {
                    commit("SET_GLOBALS_MAILS", res.data.data);
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_GLOBALS_MAILS", {});
                    reject(err);
                    console.log(err);
                });
        });
    },
    postSearchEcheance({ commit }, schema) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/contract/search?withoutPeriod=true`, schema)
                .then((res) => {
                    if (res.data.data.docs) {
                        commit("SET_CONTRACTS_LIST", res.data.data);
                        resolve(res.data.data);
                    } else {
                        commit("SET_CONTRACTS", res.data.data);
                        resolve(res.data.data);
                    }
                })
                .catch((err) => {
                    commit("SET_CONTRACTS", []);
                    reject(err);
                    console.log(err);
                });
        });
    },
    postSearchEncaissement({ commit }, schema) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `/contract/search?periodStartDate=${schema.periodStartDate}&periodEndDate=${schema.periodEndDate}`,
                    schema
                )
                .then((res) => {
                    if (res.data.data.docs) {
                        commit("SET_CONTRACTS_LIST", res.data.data);
                        resolve(res.data.data);
                    } else {
                        commit("SET_CONTRACTS_LIST", res.data.data);
                        resolve(res.data.data);
                    }
                })
                .catch((err) => {
                    commit("SET_SEARCH_CONTRACT", {});
                    console.log(err);
                    reject(err);
                });
        });
    },
    postSearchContrat({ commit }, schema) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `/contract/search?withoutPeriod=true&periodStartDate=${schema.periodStartDate}&periodEndDate=${schema.periodEndDate}`,
                    schema
                )
                .then((res) => {
                    if (res.data.data.docs) {
                        commit("SET_CONTRACTS_LIST", res.data.data);
                        resolve(res.data.data);
                    } else {
                        commit("SET_CONTRACTS_LIST", res.data.data);
                        resolve(res.data.data);
                    }
                })
                .catch((err) => {
                    commit("SET_SEARCH_CONTRACT", {});
                    console.log(err);
                    reject(err);
                });
        });
    },
    postsearchGlobalMail({ commit }, schema) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/global-mail/search?withoutPeriod=true`, schema)
                .then((res) => {
                    commit("SET_SEARCH_GLOBAL_MAIL", {
                        docs: res.data.data,
                    });
                    resolve(res.data.data);
                })
                .catch((err) => {
                    commit("SET_GLOBALS_MAILS", {});
                    reject(err);
                    console.log(err);
                });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};